.block {
  background: lightness(var(--color-bg), 0.99);
  border-bottom: 1px solid lightness(var(--color-bg), 0.7);
}

.block__logo {
  flex-shrink: 0;
  margin: 0 5px;
}

.block__aside {
  flex-basis: 190px;
  flex-shrink: 0;
  padding: 6px;
}

.block__main {
  flex-grow: 2;
}

.block__right {
  flex-shrink: 0;
  padding: 6px;
}

.block__title {
  font-weight: bold;
  font-size: 0.9rem;
}

.block--highlighted {
  @extend .block;
  background: var(--color-warning-lighter);
}

.block--separated {
  margin-bottom: 4rem;
  padding-bottom: 2rem;
  border-bottom: 2px solid var(--color-primary-darker);
}

.block--header {
  @extend .block;
  background: var(--color-contrast-low);
  display: flex;
  align-items: center;
  border: none;

  .block__logo {
    order: 1;
  }

  .block__aside {
    order: 3;
  }

  .block__main {
    order: 2;
    text-align: center;
  }

  .block__right {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--color-contrast-low);
    z-index: 99;
  }
}

@include breakpoint(md) {
  .block {
    display: flex;
    align-items: center;
  }

  .block__title {
    font-size: 1rem;
  }

  .block--header {
    .block__logo {
      order: 1;
    }

    .block__aside {
      order: 2;
    }

    .block__main {
      order: 3;
      text-align: center;
    }

    .block__right {
      order: 4;
      position: relative;
      background: transparent;
    }
  }
}

@include breakpoint(xl) {
  .block__aside {
    flex-basis: 230px;
  }

  .block__title {
    font-size: 1.1rem;
  }
}
