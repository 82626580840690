.week {
  flex-wrap: nowrap;
  padding: 10px;
  @include breakpoint(md) {
    @include flex-center(row);
    flex-wrap: nowrap;
  }
}

// @include breakpoint(md) {
//    flex: 1 0 75%;
//    display: grid;
//    grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
//  }
