@mixin flex-center($direction) {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: $direction;
}

@mixin flex($direction) {
  display: flex;
  flex-direction: $direction;
}

@mixin abs-center() {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

// edit font rendering -> tip: use for light text on dark backgrounds
@mixin fontSmooth {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// crop top space on text elements - caused by line height
@mixin lhCrop($line-height, $capital-letter: 1) {
  &::before {
    content: "";
    display: block;
    height: 0;
    width: 0;
    margin-top: calc((#{$capital-letter} - #{$line-height}) * 0.5em);
  }
}

@mixin borderbox() {
  box-sizing: border-box;
}

@mixin noselect() {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

// CSS dot
@mixin dot($width: 12px, $color: red) {
  border-radius: 50%;
  width: $width;
  height: $width;
  background: $color;
}

// CSS emptydot
@mixin emptyDot($width: 12px, $color: red, $bg: transparent) {
  border-radius: 50%;
  width: $width;
  height: $width;
  background: $bg;
  border: 2px solid $color;
}

// CSS triangle
@mixin triangle($direction: up, $width: 12px, $color: red) {
  width: 0;
  height: 0;
  border: $width solid transparent;

  @if ($direction == left) {
    border-right-color: $color;
  } @else if($direction == right) {
    border-left-color: $color;
  } @else if($direction == down) {
    border-top-color: $color;
  } @else {
    border-bottom-color: $color;
  }
}

// reset user agent style
@mixin reset {
  background-color: transparent;
  padding: 0;
  border: 0;
  border-radius: 0;
  color: inherit;
  line-height: inherit;
  appearance: none;
}

// --------------------------------

// Color functions

// --------------------------------

@mixin gradient($direction, $list) {
  background: -webkit-linear-gradient($direction, $list);
  background: -moz-linear-gradient($direction, $list);
  background: -o-linear-gradient($direction, $list);
  background: linear-gradient($direction, $list);
}

// @mixin setTextColor($bg) {
//   @if (lightness($bg) >= 50) {
//     color: var(--color-black); // Lighter backgorund, return dark color
//   } @else {
//     color: var(--color-white); // Darker background, return light color
//   }
// }

@mixin color-variant($bg, $color) {
  color: $color;
  background-color: $bg;
  .lt {
    background-color: lighten($bg, $color-percent);
  }
  .dk {
    background-color: darken($bg, $color-percent);
  }
  .bg {
    background-color: $bg;
  }
}

@mixin generateVariants($color, $name) {
  $l_list: light, lighter;
  $d_list: darker, dark;

  #{$name}: $color;
  @each $prop in $l_list {
    #{$name}-#{$prop}: lighten($color, 10%);
  }
  @each $prop in $d_list {
    #{$name}--#{$prop}: darken($color, 10%);
  }
}

// define HSL color variable
@mixin defineColorHSL($color, $hue, $saturation, $lightness) {
  #{$color}: unquote("hsl(#{$hue}, #{$saturation}, #{$lightness})");
  #{$color}-h: #{$hue};
  #{$color}-s: #{$saturation};
  #{$color}-l: #{$lightness};
}

// return color with different opacity value
@function alpha($color, $opacity) {
  $color: str-replace($color, "var(");
  $color: str-replace($color, ")");
  $color-h: var(#{$color + "-h"});
  $color-s: var(#{$color + "-s"});
  $color-l: var(#{$color + "-l"});
  @return hsla($color-h, $color-s, $color-l, $opacity);
}

// return color with different lightness value
@function lightness($color, $lightnessMultiplier) {
  $color: str-replace($color, "var(");
  $color: str-replace($color, ")");
  $color-h: var(#{$color + "-h"});
  $color-s: var(#{$color + "-s"});
  $color-l: var(#{$color + "-l"});
  @return hsl($color-h, $color-s, calc(#{$color-l} * #{$lightnessMultiplier}));
}

// replace substring with another string
// credits: https://css-tricks.com/snippets/sass/str-replace-function/
@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      );
  }
  @return $string;
}
