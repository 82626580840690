$breakpoints: (
  xs: 30rem,   // ~480px
  sm: 37.5rem, // ~600px
  md: 64rem,   // ~1024px
  lg: 80rem,   // ~1280px
  xl: 90rem    // ~1440px
);

@mixin breakpoint($breakpoint, $logic: false) {
  @if( $logic ) {
    @media #{$logic} and (min-width: map-get($map: $breakpoints, $key: $breakpoint)) { @content; }
  } @else {
    @media (min-width: map-get($map: $breakpoints, $key: $breakpoint)) { @content; }
  }
}