$logo: 40px;

.logo {
  position: relative;
  display: flex;
  align-items: flex-end;
}

.logo__icon {
  display: flex;
  align-items: flex-end;
}

.logo__icon__l {
  height: calc(#{$logo} - 5px);
}

.logo__icon__r {
  height: calc(#{$logo} - 15px);
  margin-left: -15px;
}

.logo__title {
  margin-left: 10px;
  font-size: 1.9rem;
  line-height: 1;
  font-weight: 400;
  color: var(--color-primary-dark);
}
