:root {
  // radius
  --radius: 0.25em;

  // box shadow
  --shadow-sm: 0 1px 1px rgba(0, 0, 0, .2), 0 1px 8px rgba(0, 0, 0, .1); // low depth
  --shadow-md: 0 1px 8px rgba(0, 0, 0, .1), 0 8px 24px rgba(0, 0, 0, .15); // medium depth
  --shadow-lg: 0 1px 8px rgba(0, 0, 0, .1), 0 16px 48px rgba(0, 0, 0, .1), 0 24px 60px rgba(0, 0, 0, .1); // high depth

  // animations
  // credits: https://github.com/ai/easings.net
  --transition-bounce: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  --transition-ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1);
  --transition-ease-in: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  --transition-ease-out: cubic-bezier(0.215, 0.61, 0.355, 1);
}