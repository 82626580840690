.user {
  display: flex;
  align-items: center;
  padding: 5px;
}

.user:hover {
  opacity: 0.8;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
}

.user__avatar {
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
}

.user__avatar img {
  width: 50px;
  vertical-align: top;
}

.user__main {
  margin-left: 12px;
  flex-grow: 0;
  min-width: 0;
}

.user__name {
  font-weight: bold;
  font-size: 1.1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.user__text {
  font-size: 0.9rem;
}

@include breakpoint(xl) {
  .user__avatar img {
    width: 65px;
  }
}
