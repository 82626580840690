//project list
.projects {
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;
  flex-direction: column;
  justify-content: center;
}

.projects__list {
  padding-top: 3rem;
  width: 100%;
  overflow-y: auto;
  padding: 0;
  margin: 0;
  flex-direction: column;
  justify-content: center;

  height: calc(100% - 3rem);
  @include breakpoint(lg) {
    height: calc(100% - 4rem);
  }
  @include breakpoint(xl) {
    height: calc(100% - 5rem);
  }
}

.projects__search {
  height: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto 15px;

  @include breakpoint(lg) {
    height: 4rem;
  }
  @include breakpoint(xl) {
    height: 5rem;
  }
}
