.day {
  position: relative;
  flex: 1;
  margin: 0.5vmin 0.7vmin;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
}

.day__name {
  position: absolute;
  top: 0;
  color: var(--color-contrast-low);
  font-size: 0.8em;
}

.day__name2 {
  position: absolute;
  top: 0;
  color: var(--color-contrast-medium);
  font-size: 0.9em;
}

@include breakpoint(md) {
  .day {
    margin-right: 10px;
  }
}

@include breakpoint(xl) {
  .day {
    margin: 0.9vmin 1vmin;
    margin-right: 10px;
    padding: 1.5rem 0;
  }
}
