.availabilities__wrap {
    @include flex-center(column);
}

.availabilities_slots {
    margin-top: 10px;
    transition: all 0.2s cubic-bezier(0.17, 0.67, 0.5, 0.67);
    background: var(--color-contrast-lower);
    padding: 5px;
}

.availabilities__day {
    padding: 4px;
    border: 1px solid var(--color-bg);
}

.availabilities__footer {
    margin-top: 10px;
    display: flex;
    align-items: center;
}
