.slot {
  // font-size: 0.75em;
  flex: 1;
  position: relative;
  max-height: 100%;
  min-width: 0.5rem;
  height: 36px;
  width: 3rem;
  margin: 1px;
  border-radius: 2px;
  transition: background-color 0.2s var(--transition-ease-out);

  @include flex-center(column);
  background: var(--color-contrast-low);
  color: alpha(var(--color-contrast-high), 0.5);
  cursor: pointer;

  @include breakpoint(md) {
    font-size: 0;
    width: 2vmin;
    height: 30px;
    @include flex-center(column);
  }
}

.slot__hour {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2 ease-in-out;
  bottom: -80%;
  color: alpha(var(--color-contrast-high), 0.5);
  font-size: 0.8rem;
}

.slot:hover {
  background: var(--color-contrast-medium);

  .slot__hour {
    visibility: visible;
    opacity: 1;
  }
}

.slot--disabled {
  background: alpha(var(--color-contrast-lower), 0.5);
  cursor: not-allowed;
}
.slot--disabled:hover {
  background: alpha(var(--color-contrast-lower), 0.5);
}

.slot--past {
  cursor: not-allowed;
}

.slot--current:before {
  content: "";
  position: absolute;
  left: 0;
  width: 1px;
  height: 100%;
  border-left: solid 1px var(--color-accent-dark);
  z-index: 1;
  background: var(--color-bg);
}

.slot--spaced {
  margin-right: 3px;
}

.slot--note {
  @include emptyDot(10px, var(--color-bg), var(--color-black));
  // @include abs-center();
}

.slot--label {
  position: absolute;
  top: -1.8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 4px 10px;
  background: var(--color-bg);
  color: var(--color-contrast-high);
  z-index: 3;
  font-size: 0.9rem;
  font-weight: bold;
}

.slot--label--small {
  color: var(--color-contrast-medium);
  font-weight: bold;
  position: absolute;
  top: -21px;
  left: -20px;
  font-size: 12px;
  font-size: 0.75rem;
  display: block;

  white-space: nowrap;
  padding-left: 20px;
  padding-right: 20px;

  background: linear-gradient(
    to right,
    alpha(var(--color-bg), 0) 0px,
    var(--color-bg) 15px
  );
}

.slot--menu {
  position: fixed;
  border: 1px solid lime;
}
