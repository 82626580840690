.tooltip {
  position: relative;
  z-index: 999;
}

.tooltip-item {
  font-weight: bold;
  cursor: pointer;
}

.tooltip-content {
  position: absolute;
  bottom: 100%;
  left: 50%;
  z-index: 9999;
  margin: 0 0 105px -140px;
  padding: 25px;
  width: 280px;
  border-radius: 10px/50%;
  background: #fff;
  color: #dd5864;
  text-align: left;
  font-size: 1rem;
  opacity: 0;
  cursor: default;
  transition: opacity 0.3s, transform 0.3s;
  pointer-events: none;
}
