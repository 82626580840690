$cal_radius: 1em;
$cal_selection: var(--color-accent-dark);

.calendar {
    margin:5rem;
    width: 400px;
    padding: 15px;
    box-shadow: 1px 1px 20px 0 rgba(0, 0, 0, 0.4);
    border-radius: $cal_radius;
    overflow: hidden;
    background-color:var(--color-contrast-lower);

    &--nav {
        margin: -15px -15px 15px;
        padding: 0 15px;
        background-color:var(--color-primary-dark);
        color: #fff;
        height: 70px;
        position: relative;

        a {
            position: absolute;
            cursor: pointer;
            left: 10px;
            font-size: 32px;
            line-height: 1;
            top: 16px;
            width: 30px;
            text-align: center;
            display: inline-block;
            color: var(--color-contrast-high);
            user-select: none;
            text-decoration: none;

            &:hover {
                color: var(--color-contrast-higher);
            }

            &:last-child {
                left: auto;
                right: 10px;
            }
        }

        h1 {
            margin: 0;
            position: absolute;
            left: 40px;
            right: 40px;
            text-align: center;
            cursor: pointer;
            font-weight: 400;
            font-size: 30px;
            line-height: 66px;
            user-select: none;
        }

        small {
            font-weight: 300;
            font-size: 60%;
        }
    }

    &--days {
        font-size: 0;

        span {
            width: 14.28571%;
            display: inline-block;
            text-align: center;
            user-select: none;
            cursor: pointer;
            margin: 8px 0;
            line-height: 34px;
            position: relative;
            font-size: 16px;
            &.label {
                text-transform: uppercase;
                font-weight: 700;
                color: var(--color-contrast-low);
                font-size: 14px;
                cursor: initial;
            }

            &.active {
                font-weight: 700;
                background-color: var(--color-bg);
                border-radius: $cal_radius;
            }

            &.muted {
                color: var(--color-contrast-low);
            }

            &.between {
                border-radius: 0;
            }

            &.start,
            &.between,
            &.end {
                background-color: $cal_selection;
                color: var(--color-contrast--higher);
            }

            &.start {
                border-radius: $cal_radius 0 0 $cal_radius;
            }

            &.end {
                border-radius: 0 $cal_radius $cal_radius 0;
            }

            &.start.end {
                border-radius: $cal_radius;
            }

            &.between:nth-child(7n):after,
            &.start:nth-child(7n):after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: 100%;
                background-color: $cal_selection;
                width: 20px;
            }

            &.between:nth-child(7n + 1):after,
            &.end:nth-child(7n + 1):after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                right: 100%;
                background-color: $cal_selection;
                width: 20px;
            }

            &.start.end:after {
                display: none;
            }
        }
    }
}
