.project {
  opacity: 1;
  position: relative;
  text-align: left;
  width: 100%;
  cursor: pointer;
  margin-bottom: 2px;
  display: flex;
  padding: 15px;
  font-size: 1em;
  background-color: var(--color-contrast-lower);
  transition: background-color 0.2s var(--transition-ease-out);
  color: var(--color-contrast-higher);
  border-radius: 15px;

  &:hover {
    background-color: alpha(var(--color-primary), 0.7);
  }

  @include breakpoint(md) {
    font-size: 0.79em;
  }
}

.project__aside {
}

.project__dot {
  @include dot(24px, var(--color-contrast-medium));
  margin-right: 10px;
  width: 24px;
  align-self: center;
}

.project__main {
}

.project__header {
}

.project__title {
  font-weight: bold;
  font-size: 1.1rem;
  margin-bottom: 1px;
}

.project__subtitle {
  font-size: 0.8rem;
}

.project__main {
}

.project__separator {
  width: 100%;
  height: 1px;
  border-bottom: 1px solid var(--color-contrast-medium);
}

.project__wrap {
  padding: 10px;
  display: flex;
  width: 100%;
}

.project__content {
  padding-top: 12px;
}

.project__info {
  margin-bottom: 3px;
}

.project__link {
  font-size: 0.9rem;
  display: block;
}

// project content

.project--inactive {
  @extend .project;
  opacity: 0.4;
}

.project--archived {
  @extend .project;
  opacity: 0.3;
}

.project--selected {
  @extend .project;
  background: alpha(var(--color-primary-lighter), 0.3);
  border-left: 5px solid transparent;
  &:hover {
    background: alpha(var(--color-primary-lighter), 0.4);
  }
}
