:root,
[data-theme="light"] {
  // main
  @include defineColorHSL(--color-primary-darker, 220, 7%, 46%);
  @include defineColorHSL(--color-primary-dark, 220, 7%, 56%);
  @include defineColorHSL(--color-primary, 220, 7%, 66%);
  @include defineColorHSL(--color-primary-light, 220, 7%, 76%);
  @include defineColorHSL(--color-primary-lighter, 220, 7%, 86%);

  @include defineColorHSL(--color-accent-darker, 60, 7%, 66%);
  @include defineColorHSL(--color-accent-dark, 60, 7%, 76%);
  @include defineColorHSL(--color-accent, 60, 7%, 86%);
  @include defineColorHSL(--color-accent-light, 60, 7%, 93%);
  @include defineColorHSL(--color-accent-lighter, 60, 7%, 100%);

  @include defineColorHSL(--color-black, 240, 8%, 12%);
  @include defineColorHSL(--color-white, 0, 0%, 100%);

  // feedback
  @include defineColorHSL(--color-warning-darker, 46, 100%, 47%);
  @include defineColorHSL(--color-warning-dark, 46, 100%, 50%);
  @include defineColorHSL(--color-warning, 46, 100%, 61%);
  @include defineColorHSL(--color-warning-light, 46, 100%, 71%);
  @include defineColorHSL(--color-warning-lighter, 46, 100%, 80%);

  @include defineColorHSL(--color-success-darker, 94, 48%, 42%);
  @include defineColorHSL(--color-success-dark, 94, 48%, 48%);
  @include defineColorHSL(--color-success, 94, 48%, 56%);
  @include defineColorHSL(--color-success-light, 94, 48%, 65%);
  @include defineColorHSL(--color-success-lighter, 94, 48%, 74%);

  @include defineColorHSL(--color-error-darker, 349, 75%, 36%);
  @include defineColorHSL(--color-error-dark, 349, 75%, 44%);
  @include defineColorHSL(--color-error, 349, 75%, 51%);
  @include defineColorHSL(--color-error-light, 349, 75%, 59%);
  @include defineColorHSL(--color-error-lighter, 349, 75%, 67%);

  // color contrasts
  @include defineColorHSL(--color-bg, 0, 9%, 93%);
  @include defineColorHSL(--color-contrast-lower, 340, 5%, 88%);
  @include defineColorHSL(--color-contrast-low, 340, 3%, 77%);
  @include defineColorHSL(--color-contrast-medium, 255, 2%, 45%);
  @include defineColorHSL(--color-contrast-high, 240, 5%, 19%);
  @include defineColorHSL(--color-contrast-higher, 240, 8%, 12%);
}
[data-theme="classic"] {
  // main
  // .test {
  //   color: hsl(0, 0%, 93%);
  // }

  // color: hsl(133, 70%, 76%);

  @include defineColorHSL(--color-primary-darker, 133, 70%, 56%);
  @include defineColorHSL(--color-primary-dark, 133, 70%, 66%);
  @include defineColorHSL(--color-primary, 133, 70%, 76%);
  @include defineColorHSL(--color-primary-light, 133, 70%, 86%);
  @include defineColorHSL(--color-primary-lighter, 133, 70%, 96%);

  // @include defineColorHSL(--color-primary-darker, 331, 70%, 56%);
  // @include defineColorHSL(--color-primary-dark, 331, 70%, 66%);
  // @include defineColorHSL(--color-primary, 331, 70%, 76%);
  // @include defineColorHSL(--color-primary-light, 331, 70%, 86%);
  // @include defineColorHSL(--color-primary-lighter, 331, 70%, 96%);

  @include defineColorHSL(--color-accent-darker, 18, 10%, 24%);
  @include defineColorHSL(--color-accent-dark, 18, 10%, 34%);
  @include defineColorHSL(--color-accent, 18, 10%, 44%);
  @include defineColorHSL(--color-accent-light, 18, 10%, 54%);
  @include defineColorHSL(--color-accent-lighter, 18, 10%, 64%);

  @include defineColorHSL(--color-black, 0, 0%, 17%);
  @include defineColorHSL(--color-white, 0, 0%, 100%);

  // feedback
  @include defineColorHSL(--color-warning-darker, 46, 100%, 41%);
  @include defineColorHSL(--color-warning-dark, 46, 100%, 51%);
  @include defineColorHSL(--color-warning, 46, 100%, 61%);
  @include defineColorHSL(--color-warning-light, 46, 100%, 71%);
  @include defineColorHSL(--color-warning-lighter, 46, 100%, 81%);

  @include defineColorHSL(--color-success-darker, 94, 48%, 36%);
  @include defineColorHSL(--color-success-dark, 94, 48%, 46%);
  @include defineColorHSL(--color-success, 94, 48%, 56%);
  @include defineColorHSL(--color-success-light, 94, 48%, 66%);
  @include defineColorHSL(--color-success-lighter, 94, 48%, 76%);

  @include defineColorHSL(--color-error-darker, 355, 90%, 41%);
  @include defineColorHSL(--color-error-dark, 355, 90%, 51%);
  @include defineColorHSL(--color-error, 355, 90%, 61%);
  @include defineColorHSL(--color-error-light, 355, 90%, 71%);
  @include defineColorHSL(--color-error-lighter, 355, 90%, 81%);

  // color contrasts
  @include defineColorHSL(--color-bg, 0, 0%, 28%);
  @include defineColorHSL(--color-contrast-lower, 0, 0%, 31%);
  @include defineColorHSL(--color-contrast-low, 0, 0%, 39%);
  @include defineColorHSL(--color-contrast-medium, 0, 0%, 65%);
  @include defineColorHSL(--color-contrast-high, 0, 0%, 91%);
  @include defineColorHSL(--color-contrast-higher, 0, 0%, 100%);
}

[data-theme="dark"] {
  .test {
    color: hsl(180, 65%, 68%);
    color: hsl(331, 70%, 76%);
    color: hsl(222, 14%, 15%);
    color: hsl(0, 0%, 100%);
    color: hsl(43, 100%, 49%);
    color: hsl(133, 28%, 48%);
    color: hsl(351, 74%, 56%);
    color: hsl(222, 14%, 18%);
    color: hsl(330, 100%, 50%);
  }

  // main
  @include defineColorHSL(--color-primary-darker, 180, 65%, 48%);
  @include defineColorHSL(--color-primary-dark, 180, 65%, 58%);
  @include defineColorHSL(--color-primary, 180, 65%, 68%);
  @include defineColorHSL(--color-primary-light, 180, 65%, 78%);
  @include defineColorHSL(--color-primary-lighter, 180, 65%, 88%);

  @include defineColorHSL(--color-accent-darker, 330, 100%, 50%);
  @include defineColorHSL(--color-accent-dark, 330, 100%, 60%);
  @include defineColorHSL(--color-accent, 330, 100%, 70%);
  @include defineColorHSL(--color-accent-light, 330, 100%, 80%);
  @include defineColorHSL(--color-accent-lighter, 330, 100%, 90%);

  @include defineColorHSL(--color-black, 222, 14%, 15%);
  @include defineColorHSL(--color-white, 0, 0%, 100%);

  // feedback
  @include defineColorHSL(--color-warning-darker, 43, 100%, 49%);
  @include defineColorHSL(--color-warning-dark, 43, 100%, 59%);
  @include defineColorHSL(--color-warning, 43, 100%, 69%);
  @include defineColorHSL(--color-warning-light, 43, 100%, 79%);
  @include defineColorHSL(--color-warning-lighter, 43, 100%, 89%);

  @include defineColorHSL(--color-success-darker, 133, 28%, 48%);
  @include defineColorHSL(--color-success-dark, 133, 28%, 58%);
  @include defineColorHSL(--color-success, 133, 28%, 68%);
  @include defineColorHSL(--color-success-light, 133, 28%, 78%);
  @include defineColorHSL(--color-success-lighter, 133, 28%, 88%);

  @include defineColorHSL(--color-error-darker, 351, 74%, 56%);
  @include defineColorHSL(--color-error-dark, 351, 74%, 66%);
  @include defineColorHSL(--color-error, 351, 74%, 76%);
  @include defineColorHSL(--color-error-light, 351, 74%, 86%);
  @include defineColorHSL(--color-error-lighter, 351, 74%, 96%);

  // color contrasts
  @include defineColorHSL(--color-bg, 222, 14%, 18%);
  @include defineColorHSL(--color-contrast-lower, 222, 12%, 21%);
  @include defineColorHSL(--color-contrast-low, 224, 7%, 30%);
  @include defineColorHSL(--color-contrast-medium, 223, 3%, 60%);
  @include defineColorHSL(--color-contrast-high, 210, 4%, 90%);
  @include defineColorHSL(--color-contrast-higher, 0, 0%, 100%);
}

[data-theme] {
  background-color: var(--color-bg);
  color: var(--color-contrast-high);
}
