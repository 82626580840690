

:root {
  /* style */
  --switch-width: 44px;
  --switch-height: 20px;
  --switch-padding: 2px;

  /* animation */
  --switch-animation-duration: 0.2s;
}

.switch {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  position: relative;
  width: var(--switch-width);
  height: var(--switch-height);
  border-radius: 50em;
  padding: var(--switch-padding) 0;
}

.switch__input, .switch__label {
  position: absolute;
  left: 0;
  top: 0;
}

.switch__input {
  margin: 0;
  padding: 0;
  opacity: 0;
  height: 0;
  width: 0;
  pointer-events: none;

  &:checked + .switch__label {
    background-color: var(--color-primary);
  }

  &:checked + .switch__label + .switch__marker {
    left: calc(100% - var(--switch-height) + var(--switch-padding));
  }

  &:focus + .switch__label,
  &:active + .switch__label {
    --color-shadow: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.2); // fix iOS 12 bug
    box-shadow: 0 0 0 3px var(--color-shadow);
  }
}

.switch__label {
  width: 100%;
  height: 100%;
  color: transparent;
  user-select: none;
  background-color: var(--color-contrast-low);
  border-radius: inherit;
  z-index: 1;
  transition: background var(--switch-animation-duration);
}

.switch__marker {
  position: relative;
  background-color: var(--color-white);
  width: calc(var(--switch-height) - var(--switch-padding) * 2);
  height: calc(var(--switch-height) - var(--switch-padding) * 2);
  border-radius: 50%;
  z-index: 2;
  pointer-events: none;
  box-shadow: 0 1px 1px hsla(0, 0%, 0%, 0.25);
  left: var(--switch-padding);
  transition: left var(--switch-animation-duration);
  will-change: left;
}
