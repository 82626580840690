.note {
    margin: .5rem;
    padding: .5rem;
    display: inline-flex;
    background-color: var(--color-contrast-low);
    color: var(--color-contrast-higher);
    padding: var(--space-xxxxs) var(--space-xxs);
    border-radius: 0.25em;
    cursor: pointer;
}

.note--selected {
    background-color: var(--color-contrast-higher);
    color: var(--color-contrast-low);
}
