/* --------------------------------

Title: Badge
Descr: A small label containing a text string

-------------------------------- */

.badge {
  @include reset; // reset button native style
  display: inline-flex;
  font-size: var(--text-sm);
  background-color: var(--color-contrast-low);
  padding: var(--space-xxxxs) var(--space-xxs);
  border-radius: 0.25em;
}

.badge--outlined {
  background-color: transparent;
  border: 1px solid var(--color-contrast-low);
}

.badge--high-contrast {
  background-color: var(--color-contrast-higher);
  color: var(--color-bg);
  @include fontSmooth;
}

.badge--primary {
  background-color: var(--color-primary);
  color: var(--color-white);
  @include fontSmooth;
}

.badge--error {
  background-color: var(--color-error);
  color: var(--color-white);
  @include fontSmooth;
}

.badge--success {
  background-color: var(--color-success);
  color: var(--color-white);
  @include fontSmooth;
}
