.timeline-date {
    width: 110px;
    margin-top: 20px;
}

.timeline-content {
    position: relative;
    border-left: 3px solid var(--color-accent);
    padding: 20px 30px;
}

.timeline-event:before {
    content: '';
    width: 20px;
    height: 20px;
    background: var(--color-accent);
    position: absolute;
    left: -12px;
    top: 25px;
    border-radius: 100%;
}

.timeline-user {
    font-size: 0.9rem;
    color: var(--color-accent);
}
.timeline-date {
    font-size: 0.9rem;
    color: var(--color-primary);
    margin-top: 5px;

}

.timeline-user {
    display: flex;
    align-items: center;
    margin: 10px 0 20px -20px;
}

.timeline-detail {
    font-size: 1.1rem;
}

.timeline-buttons {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}
