* {
  &::-webkit-scrollbar {
    // general scrollbar style
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    // progress bar
    background-color: alpha(var(--color-bg), 0.7);
  }
  &::-webkit-scrollbar-thumb {
    // handle
    background-color: var(--color-contrast-low);
    border-radius: 4vmin;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--color-primary-darker);
  }
  @include noselect();
}

body {
  width: 100%;
  height: 100vh;
  font-size: 1vmax;
  @include fontSmooth();
  scroll-behavior: smooth;
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

.content {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 0 20px;
  @include breakpoint(sm) {
    flex-direction: row;
    margin: 0;
  }
}

.main {
  flex: 1;
  position: relative;
  overflow: auto;
  @include breakpoint(md) {
    flex-grow: 1;
    flex-shrink: 0;
  }
}

.sidebar {
  box-shadow: var(--shadow-md);
  padding: 2px;
  flex-basis: 60vmin;
  flex-shrink: 1;
  display: flex;
  background: var(--color-bg);

  @include breakpoint(sm) {
    flex-basis: 40vmin;
  }
  @include breakpoint(md) {
    flex-direction: row;
    flex-basis: 17vw;
    flex-shrink: 1;
  }
}

.sidebar--is--visible {
  display: flex;
}
.sidebar--is--hidden {
  display: none;
}
