
.notification {
  position: fixed;
  bottom: var(--space-md);
  left: var(--space-md);
  z-index: var(--zindex-fixed-element);

  background-color: var(--color-bg);
  padding: var(--space-sm);
  box-shadow: var(--shadow-sm);
  border-radius: 0.25em;
  color: var(--color-contrast-high);
  font-size: var(--text-sm);

  transition: .2s;
  transform: translateY(16px);
  transform-origin: bottom left;
  // hide element
  opacity: 0;
  visibility: hidden;
}

.notification--is-visible {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
