.search__list__wrap {
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;
  flex-direction: column;
  justify-content: center;
}

.search__list {
  padding-top: 3rem;

  width: 100%;
  overflow-y: auto;
  padding: 0;
  margin: 0;
  flex-direction: column;
  justify-content: center;

  height: calc(100% - 3rem);
  @include breakpoint(lg) {
    height: calc(100% - 4rem);
  }
  @include breakpoint(xl) {
    height: calc(100% - 5rem);
  }
}

.search_result {
  opacity: 1;
  position: relative;
  text-align: left;
  width: 100%;
  border-radius: 1vmin 0 0 1vmin;
  border-left: 4px solid var(--color-bg);
  cursor: pointer;
  margin-bottom: 2px;
  display: flex;
  padding: 10px;
  font-size: 1em;
  background-color: var(--color-contrast-lower);
  transition: background-color 0.2s var(--transition-ease-out);
  border-bottom: 1px solid var(--color-contrast-medium);
  color: var(--color-contrast-higher);

  &:hover {
    background-color: alpha(var(--color-accent), 0.7);
  }

  @include breakpoint(md) {
    font-size: 0.79em;
  }
}

.search_result--selected {
  @extend .search_result;
  background: var(--color-accent-darker);

  &:hover {
    background-color: var(--color-primary);
  }
}
