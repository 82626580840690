
:root {
  --search-input-btn-width: 2.8em;
  --search-input-icon-size: 1.25em;
}

.search-input {
  position: relative;

  .form-control::-webkit-search-decoration {
    -webkit-appearance: none;
  }
}

.search-input--icon-right .form-control {
  padding-right: var(--search-input-btn-width);
}

.search-input--icon-left .form-control {
  padding-left: var(--search-input-btn-width);
}

.search-input__btn {
  @include reset;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: var(--search-input-btn-width);

  &:active .icon {
    transform: scale(0.9);
  }

  .icon {
    display: block;
    width: var(--search-input-icon-size);
    height: var(--search-input-icon-size);
    margin-left: auto;
    margin-right: auto;
    color: var(--color-contrast-low); // icon color
    transition: transform 0.2s;
  }
}

.search-input--icon-left .search-input__btn {
  left: 0;
  right: auto;
  pointer-events: none;
}

.search-input__btn:focus .icon,
.search-input .form-control:focus + .search-input__btn .icon {
  color: var(--color-primary); // active icon color
}
