:root {
  --drawer-width: 35vw;
  @include breakpoint(sm) {
    --drawer-width: 25vw;
  }
  @include breakpoint(sm) {
    --drawer-width: 17vw;
  }
}

.drawer--left {
  position: absolute;
  z-index: var(--zindex-overlay);
  width: 100%;

  top: 0;
  left: 0;
  transform: translateX(-100%);

  transition: transform 0.5s;
  transition-timing-function: var(--transition-ease-in-out);
  // hide drawer

  @include breakpoint(sm) {
    width: var(--drawer-width);
    height: 100%;
  }
  @include breakpoint(xs) {
    width: var(--drawer-width);
  }
}

.drawer--right {
  position: absolute;
  background: lightness(var(--color-bg), 0.7);
  z-index: var(--zindex-overlay);
  width: 100%;
  // height: 100%;
  top: 0;
  right: 0;
  transform: translateX(100%);
  transition: transform 0.5s;
  transition-timing-function: var(--transition-ease-in-out);
  // hide drawer
  @include breakpoint(sm) {
    width: var(--drawer-width);
    height: 100%;
  }
  @include breakpoint(xs) {
    width: var(--drawer-width);
  }
}

.drawer--is-visible {
  transform: translateX(0);
}

.drawer__content {
  position: relative;
  width: 100%;
  // height: 100%;
  background-color: var(--color-bg);
  box-shadow: var(--shadow-md);
  @include breakpoint(sm) {
    width: var(--drawer-width);
    height: 100%;
  }
}

.drawer__body {
  // scrollable area
  padding: var(--space-sm) var(--component-padding);
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch; // smooth scroll on iOS
}

.drawer__close-btn {
  position: fixed;
  z-index: var(--zindex-fixed-element);
  top: var(--space-xxs);
  right: var(--space-xxs);
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: alpha(var(--color-contrast-higher), 0.85);

  &:hover {
    background-color: var(--color-contrast-higher);
  }

  .icon {
    display: block;
    color: var(--color-bg);
    font-size: 16px;
  }
}

// --fixed-header
.drawer--fixed-header .drawer__content {
  overflow: auto; // flex fallback
  display: flex;
  flex-direction: column;
}

.drawer--fixed-header .drawer__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  padding: var(--space-xs) var(--component-padding);
  border-bottom: 1px solid var(--color-contrast-low);
}

.drawer--fixed-header .drawer__close-btn {
  position: static;
}

// --modal
.drawer--modal {
  width: 100%;
  background-color: alpha(var(--color-contrast-higher), 0);
  transition: background-color 0.3s;

  &.drawer--is-visible {
    background-color: alpha(var(--color-contrast-higher), 0.6);
  }

  &.drawer--open-left .drawer__content {
    right: auto;
    left: 0;
  }

  &.drawer--open-right .drawer__content {
    right: 0;
    left: auto;
  }

  @include breakpoint(xs) {
    .drawer__content {
      width: var(--drawer-width);
    }
  }
}
